exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-blog-contentful-blog-post-blog-category-category-name-contentful-blog-post-slug-jsx": () => import("./../../../src/pages/blog/{ContentfulBlogPost.blogCategory__categoryName}/{ContentfulBlogPost.slug}.jsx" /* webpackChunkName: "component---src-pages-blog-contentful-blog-post-blog-category-category-name-contentful-blog-post-slug-jsx" */),
  "component---src-pages-blog-contentful-blog-post-blog-category-category-name-index-jsx": () => import("./../../../src/pages/blog/{ContentfulBlogPost.blogCategory__categoryName}/index.jsx" /* webpackChunkName: "component---src-pages-blog-contentful-blog-post-blog-category-category-name-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-buyers-guide-index-js": () => import("./../../../src/pages/buyers-guide/index.js" /* webpackChunkName: "component---src-pages-buyers-guide-index-js" */),
  "component---src-pages-cart-index-jsx": () => import("./../../../src/pages/cart/index.jsx" /* webpackChunkName: "component---src-pages-cart-index-jsx" */),
  "component---src-pages-collections-index-jsx": () => import("./../../../src/pages/collections/index.jsx" /* webpackChunkName: "component---src-pages-collections-index-jsx" */),
  "component---src-pages-collections-shopify-collection-handle-index-jsx": () => import("./../../../src/pages/collections/{ShopifyCollection.handle}/index.jsx" /* webpackChunkName: "component---src-pages-collections-shopify-collection-handle-index-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-contentful-buying-guide-url-jsx": () => import("./../../../src/pages/{ContentfulBuyingGuide.url}.jsx" /* webpackChunkName: "component---src-pages-contentful-buying-guide-url-jsx" */),
  "component---src-pages-contentful-category-page-data-slug-jsx": () => import("./../../../src/pages/{ContentfulCategoryPageData.slug}.jsx" /* webpackChunkName: "component---src-pages-contentful-category-page-data-slug-jsx" */),
  "component---src-pages-contentful-content-page-data-url-jsx": () => import("./../../../src/pages/{ContentfulContentPageData.url}.jsx" /* webpackChunkName: "component---src-pages-contentful-content-page-data-url-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-products-index-jsx": () => import("./../../../src/pages/products/index.jsx" /* webpackChunkName: "component---src-pages-products-index-jsx" */),
  "component---src-pages-products-shopify-product-handle-jsx": () => import("./../../../src/pages/products/{ShopifyProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-products-shopify-product-handle-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */)
}

