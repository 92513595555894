import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { StoreProvider } from './src/context/store-context'
import './src/styles/global.scss'
import './src/styles/reset.css'
import './src/styles/tailwind.scss'
import './src/styles/variables.css'

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
)

export const onRouteUpdate = ({ prevLocation }) => {
  if (prevLocation) {
    sessionStorage.setItem('prevPath', prevLocation.pathname)
  }
}
